* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.home,
.services,
.products {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-family: Arial, Helvetica, sans-serif;
}

.services {
  background-image: url("/public/images/img-11.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-image: url("/public/images/img-10.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.signUp {
  background-image: url("/public/images/img-14.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.headline {
  margin-top: 30px;
  padding: 20px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  color: rgb(255, 255, 255);
}

.form {
  margin-top: 20px; /* Adjust the margin-top value */
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: auto;
  padding: 30px; /* Adjust the padding value */
  text-align: center;
  background: transparent;
  border: 2px solid rgb(162, 131, 254);
  border-radius: 20px;
  backdrop-filter: blur(15px); /* Fix the syntax for backdrop-filter */
}
.formDiv {
  background-color: transparent;
}

input {
  margin-bottom: 10px;
  padding: 20px;
  font-size: 16px;
  border: 2px solid rgb(232, 224, 255);
  border-radius: 20px;
  background-color: transparent;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  color: rgb(255, 255, 255);
}

input:focus {
  outline: none;
}

.small-button {
  padding: 10px 30px;
  font-size: 14px;
  margin: auto;
  background-color: rgb(185, 63, 247);
  border-radius: 10px;
  border: none;
  text-align: center;
  color: rgb(255, 255, 255);
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-weight: 10px;
}

.small-button:hover {
  background-color: #ffa4f4;
}

.form input::placeholder {
  color: #d39afa;
}

.form-options {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.remember-me {
  flex: 1;
  color: white;
  margin-right: 200px;
  font-size: 15px; /* Takes up the available space, pushing "Forgot Password?" to the right */
}

.forgot-password {
  text-align: right;
  color: #ffffff; /* Change the color to your preferred link color */
  text-decoration: none;
  flex: 1;
  font-size: 15px;
}

.forgot-password:hover {
  text-decoration: underline; /* Add underline on hover for the link */
}
